export default class VideoBlock {
  constructor(element) {
    this.element = element
    this.video = element.querySelector('video')
    this.button = element.querySelector('button')
    
    this.video.addEventListener('play', () => this.onPlay())
    this.video.addEventListener('pause', () => this.onPause())

    this.button.addEventListener('click', () => this.onClick())

    this.observer = new IntersectionObserver(intersections => this.onVisibilityChange(intersections))
    this.observer.observe(this.video)

    this.video.load()
  }

  onVisibilityChange(intersections) {
    if (intersections[0].intersectionRatio > 0) {
      this.video.play()
    } else {
      this.video.pause()
    }
  }

  onClick() {
    this.video.play()
  }

  onPlay() {
    this.button.classList.add('hidden')
  }

  onPause() {
    this.button.classList.remove('hidden')
  }
}
