import { LitElement, html, css } from 'lit-element'
import copy from 'clipboard-copy'
import '@webcomponents/webcomponentsjs'

class CopyPaste extends LitElement {
  static get properties() {
    return { 
      initialMessage: { type: String },
      successMessage: { type: String },
      text: { type: String}
    }
  }

  static get styles() { 
    return css`
      button {
        display: inherit;
        font-family: inherit;
        background: none;
        border: none;
        border-radius: 0;
        color: inherit;
        font: inherit;
        padding: 0;
        margin: 0;
        cursor: pointer;
      }
      button:focus {
        outline: 0;
        border: 0;
      }
      button:focus-visible {
        outline: auto;
      }
      slot {
        pointer-events: none;
      }
    `
  }

  onMouseLeave(e) {
    this.reset()
  }

  onSuccess() {
    this.message = this.successMessage
    this.resetTimeout = setTimeout(() => this.reset(), 3000)
    this.update()
  }

  onClick() {
    if (this.text) copy(this.text).then(() => this.onSuccess())
  }

  reset() {
    clearTimeout(this.resetTimeout)
    this.message = this.initialMessage
    this.update()
  }

  constructor() {
    super()
    this.text = null
    this.initialMessage = 'Click to Copy'
    this.successMessage = 'Copied to Clipboard'
    this.message = this.initialMessage
  }
  
  render() {
    return html`<button @click=${this.onClick} @mouseleave=${this.onMouseLeave}>${this.message}</button>`
  }
}

customElements.define('copy-paste', CopyPaste)
