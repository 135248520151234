import InfiniteScroll from 'infinite-scroll'
import VideoBlock from './VideoBlock'
import CarouselBlock from './CarouselBlock'

const initPost = element => {
  const videoBlocks = (Array.from(element.querySelectorAll('section.video-block')) || []).map(element => new VideoBlock(element))
  const carouselBlocks = (Array.from(element.querySelectorAll('section.image-carousel')) || []).map(element => new CarouselBlock(element))
}

document.addEventListener('DOMContentLoaded', () => {
  const main = document.querySelector('main')

  // Init first posts
  main.querySelectorAll('article').forEach(initPost)

  // Set up InfiniteScroll if necessary
  if (main.hasAttribute('data-infinite')) {
    const olderPosts = OLDER_POSTS.map(post => post.url)

    const infScroll = new InfiniteScroll( main, {
      path: function() {
        return olderPosts[this.loadCount]
      },
      append: 'article',
      history: false,
      status: '.page-load-status'
    })

    infScroll.on('append', function(response, path, posts) {
      posts.forEach(initPost)
    })
  }
})
