import imagesLoaded from 'imagesloaded'
import Flickity from 'flickity'

export default class CarouselBlock {
  constructor(element) {
    const carouselRoot = element.querySelector('.carousel')
    const carousel = new Flickity(carouselRoot, {
      cellAlign: 'left',
      wrapAround: true,
      percentPosition: false,
      cellSelector: 'img',
      imagesLoaded: true,
      pageDots: false,
      prevNextButtons: false
    })

    element.querySelector('button:first-child').addEventListener('click', () => carousel.previous())
    element.querySelector('button:last-child').addEventListener('click', () => carousel.next())
    carousel.on('change', () => {
      element.querySelector('button:first-child').classList.remove('hidden')
    })

    imagesLoaded(carouselRoot).on('progress', () => carousel.resize())
  }
}
